const getters = {
  body: state => state.page.body,
	h: state => state.page.h,
  table_header: state => state.table.table_header,
  cancerTypeName: state => state.table.cancerTypeName,
  cloudList: state => state.table.cloudList,
	pageInit: state => state.table.pageInit,
	ajaxCount: state => state.table.ajaxCount,
  token: state => state.user.token,
  index: state => state.user.index,
  id: state => state.user.id,
  username: state => state.user.username,
  email: state => state.user.email,
  image: state => state.user.image,
  password: state => state.user.password
};

export default getters;
