import Axios from "../utils/axios";
import qs from "qs";

//search
//环形图
export const Agetsearch_pie = () => {
  return Axios({
    method: "get",
    url: "/api/home-pie-chart"
  });
};
//堆叠条形图
export const Agetsearch_bar = () => {
  return Axios({
    method: "get",
    url: "/api/search/stackedbarplot/"
  });
};
//单条件搜索选项提示，t是类型，v是用户输入，
export const Agetsearch_list = data => {
  return Axios({
    method: "POST",
    url: "/api/search/searchhint/",
    data: qs.parse(data)
  });
};
//用户输入的是在cancer type类型下那就将用户输入的v放在cancer type字段里，请求对应的id
export const Agetsearch_list_id = data => {
  return Axios({
    method: "POST",
    url: "/api/search/modelsearch/",
    data: qs.parse(data)
  });
};
//单条件搜索引擎结果页modelresult的根据返回id请求表格数据
export const Agetsearch_list_id_table = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/model/modelpanel/",
    data: qs.parse(data)
  });
};
//废弃，之前的modelresult表格数据
export const Agetsearch_table = data => {
  return Axios({
    method: "POST",
    url: "/api/search/modelsearch/",
    data: qs.parse(data)
  });
};
//废弃
export const Agetsearch_tables = data => {
  return Axios({
    method: "POST",
    url: "/api/search/advancedsearch/",
    data: qs.parse(data)
  });
};
//model omics
//表格里的搜索,v是用户输入的值，k是哪个列，t是哪个表格
export const Agettable_input = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/model/tableInputSeatch/",
    data
  });
};
export const AgettableMutation_input = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/model/Mutation/search_hint/",
    data
  });
};
export const AgettableFusion_input = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/model/Fusion/search_hint/",
    data
  });
};
export const AgettableExpression_input = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/model/expression/search_hint/",
    data
  });
};

//mutation表格获取筛选框列和下拉框列以及筛选框的选项
export const Agettable_search = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/model/Mutation/mutation_table_select/",
    data: qs.parse(data)
  });
};
//mutation表格获取数据，可搜索可筛选search_data，可排序order_by_field,正序0倒序1参数desc
export const AgetMutation_table = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/model/Mutation/mutation_table/",
    data: qs.parse(data)
  });
};
//mutation下拉框
export const AgetMutation_select = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/model/Mutation/mutation_barchart_select/",
    data: qs.parse(data)
  });
};
//mutation bar
export const AgetMutation_bar = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/model/Mutation/MutationCount/",
    data: qs.parse(data)
  });
};
export const AgetFusion_table_search = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/model/Fusion/fusion_table_select/",
    data: qs.parse(data)
  });
};
export const AgetFusion_table = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/model/Fusion/fusion_table/",
    data: qs.parse(data)
  });
};
//Fusion下拉框
export const AgetFusion_select = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/model/Fusion/fusion_barchart_select/",
    data: qs.parse(data)
  });
};
//Fusion bar
export const AgetFusion_bar = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/model/Fusion/FusionFfpm/",
    data: qs.parse(data)
  });
};
export const AgetExpression_table_search = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/model/expression/expression_table_select/",
    data: qs.parse(data)
  });
};
export const AgetExpression_table = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/model/expression/expression_table/",
    data: qs.parse(data)
  });
};
//Expression下拉框
export const AgetExpression_select = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/model/expression/expression_barchart_select/",
    data: qs.parse(data)
  });
};
//Expression bar
export const AgetExpression_bar = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/model/expression/expression_logtpm/",
    data: qs.parse(data)
  });
};
export const AgetFusionbar = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/model/Fusion/fusionbarchartFFPM/",
    data: qs.parse(data)
  });
};

export const AgetExpressionbar = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/model/expression/expression_logtpm/",
    data: qs.parse(data)
  });
};
export const AgetCard = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/model/Mutation/variationclass_count/",
    data: qs.parse(data)
  });
};
export const AgetSequence = data => {
  //没有用
  return Axios({
    method: "POST",
    url: "/api/model_db/model/Mutation/sequence/",
    data: qs.parse(data)
  });
};
//immunology
export const AgetBarGroup = data => {
  //没有用
  return Axios({
    method: "POST",
    url: "/api/model_db/model/MfpData/",
    data: qs.parse(data)
  });
};
export const AgetScatter = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/model/PathwaySsgsea/",
    data: qs.parse(data)
  });
};
// gene
export const AgetTable = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/gene/Mutation/mutation_table/",
    data: qs.parse(data)
  });
};
export const AgetGeneSequence = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/gene/Mutation/Sequence/",
    data: qs.parse(data)
  });
};
export const AgetGeneCard = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/gene/Mutation/variationclass_count/",
    data: qs.parse(data)
  });
};
export const AgetGeneHeatmap = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/gene/Mutation/MutationHeatMap/",
    data: qs.parse(data)
  });
};
export const AgetGeneFusionHeatmap = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/gene/Fusion/FusionHeatMap/",
    data: qs.parse(data)
  });
};
export const AgetGeneFusionTable = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/gene/Fusion/FusionTable/",
    data: qs.parse(data)
  });
};
export const AgetGeneExpressionTable = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/gene/Expression/ExpressionTable/",
    data: qs.parse(data)
  });
};
export const AgetGeneFusionBar = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/gene/Fusion/FusionBarchartFFPM/",
    data: qs.parse(data)
  });
};
export const AgetGeneExpressionBar = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/gene/Expression/expression_logtpm/",
    data: qs.parse(data)
  });
};
//pathway
export const AselectPathwayHeatmap = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/pathway/PathwayHeatMap/",
    data: qs.parse(data)
  });
};
//Immune
//til表格
export const til_table = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/model/immune/immune_cell/",
    data: qs.parse(data)
  });
};
// subtype_table表格
export const subtype_table = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/model/immune/subtype_table/",
    data: qs.parse(data)
  });
};
// pathways_table表格
export const pathways_table = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/model/pathway/pathway_table/",
    data: qs.parse(data)
  });
};
//  subtype_table下拉框数据
export const subtype_select = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/model/immune/subtype_select/",
    data: qs.parse(data)
  });
};
//til下拉框
export const til_table_select = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/model/immune/immune_cell_select/",
    data: qs.parse(data)
  });
};
//pathways下拉框
export const pathways_table_select = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/model/pathway/pathway_table_select/",
    data: qs.parse(data)
  });
};
//柱形图
export const til_bar = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/model/immune/immune_cell_barchart/",
    data: qs.parse(data)
  });
};
export const subtype_bar = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/model/immune/subtype_barchart/",
    data: qs.parse(data)
  });
};
export const pathways_bar = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/model/pathway/pathwaycount/",
    data: qs.parse(data)
  });
};
//柱形图下拉框
export const til_bar_select = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/model/immune/immune_cell_barchart_select/",
    data: qs.parse(data)
  });
};
export const subtype_bar_select = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/model/immune/subtype_barchart_select/",
    data: qs.parse(data)
  });
};
export const pathways_bar_select = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/model/pathway/pathway_barchart_select/",
    data: qs.parse(data)
  });
};

//Cancer Biology
//表格接口
export const CancerMutation_table = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/cancer/cancer_mutation_table/",
    data: qs.parse(data)
  });
};
export const CancerFusion_table = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/cancer/cancer_fusion_table/",
    data: qs.parse(data)
  });
};
export const CancerExpression_table = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/cancer/cancer_expression_table/",
    data: qs.parse(data)
  });
};
//下拉框接口
export const CancerMutation_table_search = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/cancer/cancer_mutation_select/",
    data: qs.parse(data)
  });
};
export const CancerFusion_table_search = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/cancer/cancer_fusion_select/",
    data: qs.parse(data)
  });
};
export const CancerExpression_table_search = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/cancer/cancer_expression_select/",
    data: qs.parse(data)
  });
};
//表格输入框接口
export const Cancertable_input = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/cancer/tableInputSeatch/",
    data: qs.parse(data)
  });
};
//词云图
export const Work_cloud = data => {
  return Axios({
    method: "POST",
    url: "/api/search/workcloud/",
    data: qs.parse(data)
  });
};

export const Pathway__heat_map = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/model/pathway/pathway_heat_map/",
    data: qs.parse(data)
  });
};
export const ic_heat_map = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/model/immune/immune_cell_heatmap/",
    data: qs.parse(data)
  });
};
export const subtype_heat_map = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/model/immune/subtype_heatmap/",
    data: qs.parse(data)
  });
};
export const search_heatmap = () => {
  return Axios({
    method: "Get",
    url: "/api/search/heatmap/"
  });
};
export const gif_heatmap = () => {
  return Axios({
    method: "Get",
    url: "/api/search/getheatmap/"
  });
};
//model_HLAtable
export const modelHLA_table = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/model/hla/hlatable/",
    data: qs.parse(data)
  });
};
//model_HLAtable_select
export const modelHLA_table_select = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/model/hla/hlatable_select/",
    data: qs.parse(data)
  });
};
// mutationmap
export const Mutation_map = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/model/Mutation/heatmap/",
    data: qs.parse(data)
  });
};
//Tcr/Bcr table表格
export const modelCDR_table = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/model/tcr_bcr/tcr_bcrtable/",
    data: qs.parse(data)
  });
};
//Tcr/Bcr table表格下拉框
export const modelCDR_table_select = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/model/tcr_bcr/tcr_bcrtable_select/",
    data: qs.parse(data)
  });
};
export const Drug_table_select = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/model/drug/table_select/",
    data: qs.parse(data)
  });
};
export const Drug_table = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/model/drug/table/",
    data: qs.parse(data)
  });
};
// CDR3
// 堆叠柱形图
export const CDR_barplot = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/model/tcr_bcr/bar_plot/",
    data: qs.parse(data)
  });
};
// 和弦图
export const CDR_chorddiagram = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/model/tcr_bcr/chord_diagram/",
    data: qs.parse(data)
  });
};
// 柱形图下拉框
export const CDR_plot_select = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/model/tcr_bcr/plot_select/",
    data: qs.parse(data)
  });
};
// pathology_ClinicalAttrInfo
//获取model ClinicalAttrInfo Table 数据
export const clinical_table = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/pathology/clinical_attr_info/table/",
    data: qs.parse(data)
  });
};
// Model ClinicalAttrInfo Table 下拉框数据
export const clinical_table_select = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/pathology/clinical_attr_info/table_select/",
    data: qs.parse(data)
  });
};
