// 封装localhost和Session

// 本地储存
export const Local = {
    // 设置永久缓存
    set (key, value, expire) {
      var data = { value: value, expire: expire };
      localStorage.setItem(key, JSON.stringify(data));
    },
    // 获取永久缓存
    get (key) {
      var data = localStorage.getItem(key);
      if (data != null) {
          var obj = JSON.parse(data);
          if (obj.expire != null && new Date().getTime() > obj.expire) {
              localStorage.removeItem(key);
          } else {
              return obj.value;
          }
      }
      return null;
    },
    // 移除永久缓存
    remove (key) {
      window.localStorage.removeItem(key);
    },
    // 移除全部永久缓存
    clear () {
      window.localStorage.clear();
    },
  };
  
  // 临时储存
  export const Session = {
    // 设置临时缓存
    set (key, val) {
      window.sessionStorage.setItem(key, JSON.stringify(val));
    },
    // 获取临时缓存
    get (key) {
      let json = window.sessionStorage.getItem(key);
      return JSON.parse(json);
    },
    // 移除临时缓存
    remove (key) {
      window.sessionStorage.removeItem(key);
    },
    // 移除全部临时缓存
    clear () {
      window.sessionStorage.clear();
    },
  }
  
  export default {
    Local,
    Session
  }