import axios from "axios";
import { ref } from "vue";
import $ from "jquery"
const loading = ref(true);
const loadingShow = () => {
  loading.value = false;
};
let isEmpty=(o)=>{
	if(Object.prototype.toString.call(o) === '[object Object]'){
		if(Object.keys(o).length==0){
			return true
		}else{
			return false
		}
	}else if(Array.isArray(o)){
		if(o.length==0){
			return true
		}else{
			return false
		}
	}else{
		if(o===''||o===null||o===undefined){
			return false
		}else{
			return true
		}
	}
}
let imgToBlob = async (url) => {
  return await axios({
    responseType: "arraybuffer",
    headers: {
      Authorization: "DB " + localStorage.getItem("token")
    },
    method: "get",
    url
  }).then(async function (res) {
    console.dir(res);
    const blob = new Blob([res.data], {
      type: "image/jpeg"
    });
    let objectURL = URL.createObjectURL(blob);
    return objectURL;
  });
};
let base64ToBlob = async(base64)=>{
	const bstr = atob(base64.replace(/^[^,]+,/, ''))
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
	const blob = new Blob([u8arr], {
		type: "image/jpeg"
	});
  let objectURL = URL.createObjectURL(blob);
  return objectURL;
}
let pxToRem = (px,base=1920)=>{
	return px / base* 10
}
let pxToVw = (px,base=1920)=>{
	return (px * 100) / base
}
let getScale =(className)=>{
	// console.log(className,document.getElementsByClassName(className))
	let transform = className?document.getElementsByClassName(className)[0].style.transform:document.getElementById("app").style.transform;
  let scaleArr = transform.match(/scale(\((.+?)\))/);
//   console.log(scaleArr);
  let scale = scaleArr&&scaleArr[2];
//   console.log(scale);
	return scale;
}
let setScreenShot=(target)=>{
// 	console.log(target)
// 	console.dir($(target))
// // console.dir(document.querySelector(target))
// let jsDom = $(target)[0]
// console.dir(jsDom)
	function toBlur(){
		let cssText = "-webkit-filter: blur(5px);-moz-filter: blur(5px);-ms-filter: blur(5px);-o-filter: blur(5px);filter: blur(5px);"
		$("body").css({
			"cssText":cssText
		})
	}
	function toClear(){
		let cssText = "-webkit-filter: blur(0px);-moz-filter: blur(0px);-ms-filter: blur(0px);-o-filter: blur(0px);filter: blur(0px);"
		$("body").css({
			"cssText":cssText
		})
	}
	document.body.oncopy = function(){return false};
	// document.addEventListener('mouseover',function(e){
	// 	console.log("mouseover",e)
	// 	toClear()
	// })
	// //
	document.addEventListener('click',function(e){
		// console.log("click",e)
		toClear()
	})
	// 快捷键
	document.addEventListener('keydown', (e) => {
		// console.log("keydown",e)
		if(e.ctrlKey == true||e.shiftKey == true||e.altKey == true){
			toBlur()
			}
	});
	// printscreen
	document.addEventListener('keyup', (e) => {
		// console.log("keyup",e)
		if (e.key == 'PrintScreen'||e.ctrlKey == true||e.shiftKey == true||e.altKey == true) {
			toBlur()
		}
	});
	//其他工具
	document.addEventListener('mouseleave', (e) => {
		// console.log("mouseleave",e)
		toBlur()
	});
	//恢复
	document.addEventListener('mouseenter',function(e){
		// console.log("mouseenter",e)
		if(!document.hasFocus()){
			// console.log("mouseenter no focus to blur")
			toBlur()
		}else{
			// console.log("mouseenter focus to clear")
			toClear()
		}
	})
}
export { loading, loadingShow ,imgToBlob,base64ToBlob,isEmpty,pxToRem,pxToVw,getScale,setScreenShot};
