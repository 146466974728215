import Axios from "../utils/axios";
import qs from "qs";
//*tab切换 tab选项请求
export const tab_count = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/label/getlabelcount/",
    data: qs.parse(data)
  });
};
//*name转id cancertype,modelname,msi,ic都要转为id进行搜索
export const Name_id = data => {
  return Axios({
    method: "POST",
    url: "/api/search/nametoid/",
    data: qs.parse(data)
  });
};
export const menu_header = data => {
  return Axios({
    method: "get",
    url: "/api/model_db/rbac/menu/header/",
    data: qs.parse(data)
  });
};
//联系我们 2023/10/13
export const contact_us = data => {
  return Axios({
    method: "POST",
    url: "/api/contact-us/",
    data: qs.parse(data)
  });
};