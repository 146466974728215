import Axios from "axios";
import qs from "qs";
import AES from "./AES";
import { ElLoading, ElMessage, ElMessageBox } from "element-plus";
// import { computed } from "vue";
// import { sendBeacon, base } from './error'
// import {Message,MessageBox} from 'element-ui'
import router from "../router";
import store from "../store";
import { markRaw } from 'vue'
import { Warning } from '@element-plus/icons-vue'
Axios.defaults.baseURL = import.meta.env.VITE_APP_BASEAPI;
const redirectLogin = function redirectLogin() {
  router.push({
    name: "login",
    query: {
      redirect: router.currentRoute.fullPath
    }
  });
};
let isRefreshing =false//是否正在刷新
let requests = []//重试队列
// 用户令牌是否已过期
const redirectQueryLogin = function redirectLogin() {
	return new Promise((resolve)=>{
		ElMessageBox.alert(
			'The login status has expired. You can log in again.',
			'System prompt',
			{
				// if you want to disable its autofocus
				// autofocus: false,
				type: 'warning',
				// icon: markRaw(Warning),//自定义图标
				confirmButtonText: 'OK',
				callback: (action) => {
					const urlParams = new URLSearchParams(window.location.search);
					const redirectPath = urlParams.get("redirect");
					if(redirectPath){
						// router.push({path:"/login",query:{redirect:redirectPath}});
            const routeUrl = router.resolve({path:"/login",query:{redirect:redirectPath}});
            window.open(routeUrl.href, "_self");
					}else{
						let path = window.location.pathname+window.location.search+window.location.hash
						// router.push({path:"/login",query:{redirect:path}})
            const routeUrl = router.resolve({path:"/login",query:{redirect:path}});
            window.open(routeUrl.href, "_self");
					}
					window.localStorage.clear();
					isRefreshing = false;
					requests=[]
				},
			}
		)
		// resolve("refresh_token")
		return new Promise(resolve=>{
			resolve("refresh_token")
		})
	})

};
Axios.defaults.timeout = 300000;
// Axios.defaults.withCredentials = true;
// 定义⼀个请求次数的变量，⽤来记录当前页⾯总共请求的次数
// let loadingRequestCount = 0;
// let loadingInstance;
// 显⽰loading的函数并且记录请求次数 ++
// const showLoading = () => {
//     if (loadingRequestCount === 0) {
//       // 全局实现loading效果，不⽤每个页⾯单独去v-loading
//       // loading样式
//       loadingInstance = ElLoading.service({
//         lock: true,
//         text: "Loading,please wait...",
//       });
//     }
//     loadingRequestCount++;
//   };
//   // 隐藏loading的函数，并且记录请求次数 --
//   const hideLoading = () => {
//     if (loadingRequestCount <= 0) return;
//     loadingRequestCount--;
//     if (loadingRequestCount === 0) {
//       loadingInstance.close();
//     }
//   };
// Axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded';
// Axios.defaults.headers['Content-Type'] = 'application/json';
// Axios.defaults.transformRequest = data => qs.stringify(data);
const noTokenUrl = [
  "/api/search/getmapping/",
  "/api/model_db/model/cancertypeshortname/",
  "/api/search/stackedbarplot/",
  "/api/search/heatmap/",
  "/api/search/workcloud/",
  "/api/search/getheatmap/",
  "/api/auth/imagecode/",
  "/api/auth/findpassword/",
  "/api/auth/changepassword/",
  "/api/auth/tokentouserinfo/",
	"/api/authorizations/"//获取token接口不需要token
];
// let loadingUrl = [
//     // 'heatmap',
//     // 'bar_plot',
//     // 'chord_diagram',
//     // 'barchart'
// ]
Axios.interceptors.request.use(
  config => {
    //todo 清除缓存
    const url = config.url; //赋值config.url
    // config.url = url + '?time=' + Math.random()
    // config.url = config.url.indexOf("?") == "-1" ? config.url + "?time=" + Math.random() : config.url + "&time=" + Math.random();
		config.url = config.url.indexOf("time") == "-1" ? config.url + "?time=" + Math.random() : config.url;
    //todo 全局loading
    // if(config.url.includes('heatmap')||config.url.includes('bar_plot')||config.url.includes('chord_diagram')||config.url.includes('barchart')){
    //     showLoading();
    // }
    // let loadingFlag = loadingUrl.find((item)=>{
    //     return config.url.includes(item)
    // })
    // if(loadingFlag){
    //     showLoading();
    // }
    //接口参数加密处理
    if (config.method === "post") {
      // config.data = {
      //   encryptInfo: AES.encrypt(JSON.stringify(config.data))
      // };
			config.data = Object.assign({},{
        encryptInfo: AES.encrypt(JSON.stringify(config.data))
      });
      if (config.responseType === "form") {
        // const data = qs.parse(config.data);
        config.headers = {
          "Content-Type": "application/x-www-form-urlencoded"
        };
        // config.data = qs.stringify({
        //   ...data
        // });
      } else {
        // eslint-disable-next-line no-unused-vars
        // const data = qs.parse(config.data);
        config.headers = {
          "Content-Type": "application/json"
        };
      }
    } else if (config.method === "get") {
      // config.params = {
      //   ...config.data
      // };
			config.params = Object.assign({},{
        ...config.data
      });
    }else if(config.method === "put"){
      config.data = Object.assign({},{
        encryptInfo: AES.encrypt(JSON.stringify(config.data))
      });
    }
    //todo 哪些是需要token的
    // 不携带Authorization
    // if (noTokenUrl.indexOf(config.url) == -1) {
    if (noTokenUrl.indexOf(url) == -1) {
      const token = localStorage.getItem("token") ? localStorage.getItem("token") : localStorage.getItem("tokenp");
      // token && (config.headers.Authorization = "DB " + token);
			if(token){
				config.headers.Authorization = "DB " + token
			}else{
        // if(url!="/api/contact-us/"){ //contact-us没有token不提示
          redirectQueryLogin()
        // }
			}
    } else {
      config.headers.Authorization = "";
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

Axios.defaults.validateStatus = status => {
  return /^(2|3)\d{2}$/.test(status);
};

Axios.interceptors.response.use(
  response => {
    //接口返回数据，解密处理
		//todo loading
    // setTimeout(() => {
    //     hideLoading();
    // }, 0);
    if (response.data.encryptInfo != undefined) {
      const encryptInfo = response.data.encryptInfo
        .replace(/\s+/g, "")
        .replace(/<\/?.+?>/g, "")
        .replace(/[\r\n]/g, "")
        .replace(" ", "");
      response.data = JSON.parse(AES.decrypt(encryptInfo));
    }

    return response;
  },
  async error => {
    const { response } = error;
    // setTimeout(() => {
    //     hideLoading();
    // }, 0);
    if (response) {
      const { status } = response;
      if (status == 400) {
        // ElMessage.error("Request parameter error");
        ElMessage.error(`${response.data.message}`);
      } else if (status == 401) {

				if(!isRefreshing){

					isRefreshing = true;
					redirectQueryLogin().then((res)=>{

						error.config.baseURL = '';
						error.config.headers['Authorization'] = 'DB  ' + localStorage.getItem("token");
						error.config.headers.withCredentials = true;
						requests.length>0&&requests.map((cb)=>{cb()})
						requests = []
						return axios.request(error.config)
					}).catch(err=>{

					}).finally(()=>{
						isRefreshing = false
					})

				}else{

					return new Promise((resolve)=>{
						requests.push(()=>{
							error.config.headers['Authorization'] = 'DB  ' + localStorage.getItem("token");
							error.config.headers.withCredentials = true;
							resolve(axios.request(error.config))
						})
					})
				}
      } else if (status == 403) {
				ElMessage.error("Operation too frequent, please try again later!");
			}else if (status == 404) {
				return Promise.reject(error);
        // ElMessage.error(`${response.data.message}`);
        // router.push('/404')
      } else if (status == 429) {
        ElMessage.error(`${response.data.message}`);
      } else if (status > 404 && status < 500) {
        ElMessage.error(response.data.message, error.message);
      } else if (status >= 500) {
        ElMessage.error("Server error, please contact the administrator");
      } else if (error.request) {
        ElMessage.error(`${response.data.message}`);
      } else {
        ElMessage.error(`${response.data.message}`);
      }
      return Promise.reject(error);
    } else {
      ElMessage.error(`${error}`);

      const routerValue = router.currentRoute._value;
      const routeParams = { path: routerValue.path, hash: routerValue.hash, query: routerValue.query };
      const routeUrl = router.resolve(routeParams);

      // let url = baseUrl + `/api/http`;
      // let options = {
      //     ...base,
      //     eventType: 'http',
      //     eventId: 'axios',
      //     url: window.location.href,
      //     triggerTime: Date.now(),
      //     src: routeUrl
      // }
      // sendBeacon(url, options)
      return Promise.reject(error);
      // window.open(routeUrl.href, '_self');
    }
  }
);

export default Axios;
