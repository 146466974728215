const theme = {
  state: {
    body: window.localStorage.getItem("theme") || "",
		h:""
  },

  mutations: {
    SET_BODY: (state, body) => {
      state.body = body;
      window.localStorage.setItem("theme", body);
    },
		SET_H:(state,h)=>{
			state.h = h;
		}
  },

  actions: {
    GetBody({ commit }, data) {
      commit("SET_BODY", data);
    },
		GetH({commit},h){
			commit("SET_H",h)
		}
  }
};

export default theme;
