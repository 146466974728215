import { get_token, get_password, get_user, get_logout } from "../../api/login";
import { menu_header } from "../../api/comm";

const user = {
  state: {
    token: window.localStorage.getItem("token") || "",
    index: window.localStorage.getItem("index") || "",
    id: window.localStorage.getItem("id") || "",
    username: window.localStorage.getItem("username") || "",
    email: window.localStorage.getItem("email") || "",
    image: window.localStorage.getItem("image") || "",
    password: window.localStorage.getItem("password") || "",
    menu_header: [],
		for_bd:true,
		can_export_img:window.localStorage.getItem("can_export_img")||false,
    need_change_password:"",
    token_password: window.sessionStorage.getItem("token") || ""
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
      window.localStorage.setItem("token", token);
    },
    SET_INDEX: (state, index) => {
      state.index = index;
      window.localStorage.setItem("index", index);
    },
    SET_ID: (state, id) => {
      state.id = id;
      window.localStorage.setItem("id", id);
    },
    SET_USERNAME: (state, username) => {
      state.username = username;
      window.localStorage.setItem("username", username);
    },
    SET_EMAIL: (state, email) => {
      state.email = email;
      window.localStorage.setItem("email", email);
    },
    SET_IMAGE: (state, image) => {
      state.image = image;
      window.localStorage.setItem("image", image);
    },
    SET_PASSWORD: (state, password) => {
      state.password = password;
      window.localStorage.setItem("password", password);
    },
    SET_HEADER: (state, menu_header) => {
      state.menu_header = menu_header;
      window.localStorage.setItem("header", JSON.stringify(menu_header));
    },
		SET_FORDB: (state, for_bd) => {
      state.for_bd = for_bd;
      window.localStorage.setItem("for_bd", for_bd);
    },
		SET_CAN_EXPORT_IMG: (state,can_export_img) => {
			state.can_export_img = can_export_img;
			window.localStorage.setItem("can_export_img",can_export_img)
		},
		SET_NEES_CHANGE_PASSWORD: (state, need_change_password) => {
      state.need_change_password = need_change_password;
      // window.sessionStorage.setItem("need_change_password", need_change_password);
      window.sessionStorage.setItem("need_change_password", need_change_password);
    },
		SET_TOKEN_PASSWORD: (state, token_password) => {
      state.token_password = token_password;
      window.localStorage.setItem("tokenp", token_password);
    }
  },

  actions: {
    // 登录
    Login({ commit }, user) {
      return new Promise((resolve, reject) => {
        get_token(user)
          .then(res => {
            if (res.data.code == 200) {
              if(res.data.data_info.need_change_password == true){
                commit("SET_TOKEN_PASSWORD", res.data.data_info.token)
                commit("SET_NEES_CHANGE_PASSWORD", res.data.data_info.need_change_password);
              }else {
                commit("SET_TOKEN", res.data.data_info.token);
                commit("SET_INDEX", res.data.data_info.index_page);
              }
              resolve(res);
            } else {
              resolve(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    // 获取用户信息
    GetUserInfo({ commit }) {
      return new Promise((resolve, reject) => {
        get_user()
          .then(res => {
            commit("SET_ID", res.data.data_info.id);
            commit("SET_USERNAME", res.data.data_info.username);
            commit("SET_EMAIL", res.data.data_info.email);
            commit("SET_IMAGE", res.data.data_info.image);
						commit("SET_FORDB", res.data.data_info.for_bd);
						commit("SET_CAN_EXPORT_IMG", res.data.data_info.can_export_img);
            resolve(res);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    //修改密码
    GetPassword({ commit }, data) {
      return new Promise((resolve, reject) => {
        get_password(data)
          .then(res => {
            commit("SET_PASSWORD", res.data.data_info.password);
            window.localStorage.clear();
            resolve(res);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    // 登出
    LogOut({ commit }) {
      get_logout().then(res => {
        commit("SET_TOKEN", "");
        window.localStorage.clear();
      });
    },
    //获取header
    GetHeader({ commit }) {
      // SET_HEADER
      return new Promise((resolve, reject) => {
        menu_header()
          .then(res => {
            commit("SET_HEADER", res.data.data_info.header);
            resolve(res);
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  }
};

export default user;
