import { tab_header, Agetsearch_cancerType } from "../../api/mapping";
import { Work_cloud } from "../../api/model";
import {Local} from '../../utils/cache'
const table = {
  state: {
    table_header: {},
    cancerTypeName: {},
    cloudList: [],
		pageInit:false,
  },
  mutations: {
    SET_TABLEHEADER: (state, table_header) => {
      state.table_header = table_header;
    },
    SET_CANCERTYPENAME: (state, cancerTypeName) => {
      state.cancerTypeName = cancerTypeName;
    },
    SET_CLOUDLIST: (state, cloudList) => {
      state.cloudList = cloudList;
    },
		SET_PAGEINIT: (state, pageInit) => {
      state.pageInit = pageInit;
			// window.localStorage.setItem("pageInit", pageInit);
    },

  },
  actions: {
    GetTABLEHEADER({ commit }) {
      if(Local.get("mapping")!==null){
        commit("SET_TABLEHEADER",Local.get("mapping"))
      }else {
        tab_header().then(res => {
          commit("SET_TABLEHEADER", res.data.data_info);
          Local.set("mapping", res.data.data_info,new Date().getTime() + 60*1000*5);
        });
      }
    },
    GetCANCERTYPENAME({ commit }) {
      if(Local.get("typeName")!==null){
        commit("SET_CANCERTYPENAME",Local.get("typeName"))
      }else {
        Agetsearch_cancerType().then(res => {
          commit("SET_CANCERTYPENAME", res.data.data_info);
          Local.set("typeName", res.data.data_info,new Date().getTime() + 60*1000*5);
        });
      }

    },
    GetCLOUDLIST({ commit }) {
      Work_cloud().then(res => {
        commit("SET_CLOUDLIST", res.data.data_info);
      });
    },
		GetPAGEINIT({ commit },pageInit) {
			commit("SET_PAGEINIT", pageInit);
		},

  }
};
export default table;
