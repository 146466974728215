// 水印添加方法
const watermark = {};

const setWatermark = str => {
  const id = "1.23452384164.123412415";

  if (document.getElementById(id) !== null) {
    document.body.removeChild(document.getElementById(id));
  }

  const can = document.createElement("canvas");
  can.width = 280;
  can.height = 150;

  const cans = can.getContext("2d");
  cans.rotate((-20 * Math.PI) / 180);
  cans.font = "20px Arial";
  cans.fillStyle = "rgba(17,17,17,0.25)";
  cans.textAlign = "left";
  cans.textBaseline = "Middle";
  // cans.fillText(str, can.width / 3-40, can.height / 2)
  // cans.fillText(date, can.width / 3-60, can.height/2 + 22)
  cans.fillText(str, can.width / 3 - 40, can.height, 100); // 水印在画布的位置x，y轴
  //   cans.fillText(date, can.width /3-40, can.height+22,200)
  const div = document.createElement("div");
  div.id = id;
  div.style.pointerEvents = "none";
  div.style.top = "0px";
  div.style.left = "0px";
  div.style.position = "fixed";
  div.style.zIndex = "100000";
  div.style.width = document.documentElement.clientWidth + "px";
  div.style.height = document.documentElement.clientHeight + "px";
  div.style.background = "url(" + can.toDataURL("image/png") + ") left top repeat";
  document.body.appendChild(div);
  return id;
};

// 该方法只允许调用一次
watermark.set = (str, container) => {
  const id = setWatermark(str, container);

  // setInterval(() => {
  //   if (document.getElementById(id) === null) {
  //     id = setWatermark(str)
  //   }
  // }, 500)
  // window.onresize = () => {
  //   setWatermark(str, container)
  // }
};
// watermark.set = (str,date) => {
//     let id = setWatermark(str,date) // str,date代表的是两行水印。如果需好几个的话再追加。
//     setInterval(() => {
//         if (document.getElementById(id) === null) {
//             id = setWatermark(str,date)
//         }
//     }, 500);
//     window.onresize = () => {
//         setWatermark(str,date)
//     };
// }
export default watermark;
