import { createStore } from "vuex";
import page from './modules/page'
import user from "./modules/login";
import table from "./modules/table";
import getters from "./getters";
const store = createStore({
  modules: {
    page,
    user,
    table
  },
  getters
});

export default store;
