import Axios from "../utils/axios";
import qs from "qs";
//table mapping
export const tab_header = () => {
  return Axios({
    method: "get",
    url: "/api/search/getmapping/"
  });
};

//bar cancertype name
export const Agetsearch_cancerType = () => {
  return Axios({
    method: "POST",
    url: "/api/model_db/model/cancertypeshortname/"
  });
};
//power
export const Agetpage_power = data => {

  return Axios({
    method: "POST",
    url: "/api/model_db/rbac/menu/tree/",
    data: qs.parse(data)
  });
};
//2023/3/29  首页搜索权限接口添加
export const menu_search = data => {
  return Axios({
    method: "POST",
    url: "/api/model_db/rbac/menu/search/",
    data: qs.parse(data)
  });
};
